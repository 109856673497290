import formSubmit from './formSubmit';

function app() {
  return {
    images: [],
    q: "",
    loading: false,
    icon: false,
    ...formSubmit({ validators: [{ name: "title" }]  }),
    getImages() {
      this.loading = true;
      fetch("/services/get/" + this.q)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log("data", data);
          this.formMessage = data.message;
          this.loading = false;
          if (data.type === "success") {
            this.success = true;
            this.images = data.images;
          }
          if (data.type === "failure") {
            this.failure = true;
          }
          this.loading = false;

          this.removeNotification();
        })
        .catch((e) => {
          console.error("e", e);
          this.failure = true;
          this.loading = false;
          this.formMessage =
            "There was an error processing your request. Please try again later.";
          this.removeNotification();
        });
    },
    removeImage(img, pageName) {
      const formData = new FormData();
      formData.set("name", pageName);
      formData.set("delete_image", true);
      formData.set("image", img);

      fetch("/services/post/", {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log("data", data);
          if (data.type === "success") {
            this.formMessage = data.message;
            this.images = data.images;
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  };
}

export default app;
