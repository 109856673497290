/**
 * formSubmit handles form submissions for saving / adding pages
 * @param {object} config - Object of config properties:
 * @property {array} checkboxes - Array of strings [checkbox string names] needed to append to form
 * @property {array} validators - Array of strings [field string names] that are required
 */
const formSubmit = function (config = {}) {
  const { checkboxes = [], validators = [] } = config;
  return {
    formMessage: "",
    success: false,
    failure: false,
    validationErrors: {},
    removeNotification() {
      setTimeout(() => {
        this.success = false;
        this.failure = false;
      }, 3000);
    },
    submitForm(form) {
      const isValid = this.validateForm(form);

      if (isValid) {
        let formData = new FormData(form.target);

        this.success = false;
        this.failure = false;
        this.formMessage = "";

        checkboxes.forEach((chkName) => {
          const check = form.target[chkName].checked ? 1 : 0;
          formData.append(chkName, check);
        });

        fetch("/services/post/", {
          method: "POST",
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log("data", data);
            this.formMessage = data.message;
            if (data.type === "success") {
              this.success = true;
              this.images = data.images;
            }
            if (data.pageURL) {
              window.location.replace(data.pageURL);
            }
            if (data.type === "failure") {
              this.failure = true;
            }

            this.removeNotification();
          })
          .catch((e) => {
            console.error("e", e);
            this.failure = true;
            this.formMessage =
              "There was an error processing your request. Please try again later.";
            this.removeNotification();
          });
      }
    },

    validateForm(form) {
      const elms = form["srcElement"].elements;
      // resets
      this.validationErrors = {};
      this.failure = false;
      this.formMessage = "";

      for (el in elms) {
        const fieldName = elms[el].name;
        const fieldValue = elms[el].value?.trim();
        const requiredNames = validators.map((v) => v.name);

        if (requiredNames.includes(fieldName) && fieldValue.length < 1) {
          this.validationErrors[fieldName] = true;
          this.failure = true;
          this.formMessage = "Required fields are missing.";
          this.removeNotification();
        }
      }
      if (Object.keys(this.validationErrors).length > 0) return false;
      return true;
    },

    handleOnInput(e) {
      if (e.target.value.trim()) {
        delete this.validationErrors[e.target.name];
      }
    },
  };
};

export default formSubmit;
